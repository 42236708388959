import { useEffect, useState } from "react"
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap"
import swal from "sweetalert"
import {
  showErrorToastr,
  showSuccessToastr,
} from "../../../../../components/Common/toastr"
import { callPatchApi } from "../../../../../utils/api"
import { errorHandler } from "../../../../../utils/error"
import { normalizeStatus } from "../../../../../utils/status"
import {
  getCategoryAndSubcategoryReasons,
  REASON_CATEGORY_SUBCATEGORY_NOT_DEFINED,
} from "../modify"
import "./status.scss"

export const backoffice_frontend_source = "backoffice_frontend"

const subscriptionCancelledStatus = "Cancelled"
const subscriptionCancelledDueOrderFailedStatus = "Cancelled Due Order Fail"

const DropdownComponent = ({
  index,
  title,
  valueKey,
  dropdownOpen,
  toggleDropdown,
  selectedDropdownValues,
  handleSelectedDropdownValues,
  handleFormInput,
  defaultValues,
  handleCategoryChange,
  categoriesKeysMap,
}) => (
  <>
    <span>{title}</span>
    <Dropdown isOpen={dropdownOpen[index]} toggle={() => toggleDropdown(index)}>
      <DropdownToggle caret>
        {selectedDropdownValues[index] || "Select"} {}
      </DropdownToggle>
      <DropdownMenu>
        {defaultValues[valueKey] &&
          defaultValues[valueKey].map(value => (
            <DropdownItem
              key={value}
              onClick={() => {
                handleSelectedDropdownValues(index, value)
                handleFormInput(
                  valueKey,
                  valueKey === "reasonCategory"
                    ? categoriesKeysMap[value]
                    : value
                )
                if (handleCategoryChange) {
                  handleCategoryChange(value)
                }
              }}
            >
              {value}
            </DropdownItem>
          ))}
      </DropdownMenu>
    </Dropdown>
  </>
)

const isCancellingSubscription = formStatusSelected =>
  [
    subscriptionCancelledDueOrderFailedStatus,
    subscriptionCancelledStatus,
  ].includes(formStatusSelected)

function SubscriptionStatusModal({
  isOpen,
  toggle,
  subscription,
  toggleIsUpdated,
}) {
  const [defaultValues, setDefaultValues] = useState({
    reasonCategory: [],
    reasonSubcategory: [],
    status: [],
  })
  let [formValues, setFormValues] = useState({
    reasonCategory: "",
    reasonSubcategory: "",
    status: "",
    extra_comment: "",
  })

  const [isDropdownOpen, setIsDropdownOpen] = useState({})
  const [selectedDropdownValues, setSelectedDropdownValues] = useState({})
  const [subcategoriesLabelsMap, setSubcategoriesLabelsMap] = useState({})

  const [categoriesKeysMap, setCategoriesKeysMap] = useState({})
  const [subcategoriesKeysMap, setSubcategoriesKeysMap] = useState({})

  const [store, setStore] = useState({})
  const [isFormValid, setIsFormValid] = useState(false)

  useEffect(() => {
    if (!isOpen) {
      return
    }

    getStatuses()
    setCategoryAndSubcategoryReasons()
  }, [isOpen])

  useEffect(() => {
    if (isOpen) {
      return
    }

    setDefaultValues({
      reasonCategory: [],
      reasonSubcategory: [],
      status: [],
    })
    setFormValues({
      reasonCategory: "",
      reasonSubcategory: "",
      status: "",
    })

    setIsDropdownOpen({})
    setSelectedDropdownValues({})
    setSubcategoriesLabelsMap({})
    setIsFormValid(false)
  }, [isOpen])

  useEffect(() => {
    const isStatusValid = defaultValues.status.includes(formValues.status)

    let isFormValid =
      isStatusValid && formValues.reasonCategory && formValues.reasonSubcategory

    if (!isFormValid) {
      return setIsFormValid(isFormValid)
    }

    const isReasonCategoryValid = !!store[formValues.reasonCategory]
    const isReasonSubcategoryValid =
      isReasonCategoryValid &&
      store[formValues.reasonCategory]["subcategories"].some(
        subcategoryMap => subcategoryMap[formValues.reasonSubcategory]
      )

    isFormValid =
      isFormValid && isReasonCategoryValid && isReasonSubcategoryValid

    setIsFormValid(isFormValid)
  }, [formValues])

  const getStatuses = async () => {
    const statuses = [
      "Active",
      "Paused",
      "Pending",
      subscriptionCancelledStatus,
      "No Existing Items",
      subscriptionCancelledDueOrderFailedStatus,
    ]

    setDefaultValues(prevState => ({
      ...prevState,
      status: statuses,
    }))
  }

  const setCategoryAndSubcategoryReasons = () => {
    const result = getCategoryAndSubcategoryReasons(subscription)

    const store = result[0]
    const categoriesKeysMap = result[1]
    const subcategoriesKeysMap = result[2]
    const categoriesLabels = result[3]
    const subcategoriesLabels = result[4]

    setStore(store)
    setCategoriesKeysMap(categoriesKeysMap)
    setSubcategoriesKeysMap(subcategoriesKeysMap)
    setSubcategoriesLabelsMap(subcategoriesLabels)

    setDefaultValues(prevState => ({
      ...prevState,
      reasonCategory: categoriesLabels,
    }))
  }

  const onUpdateFail = error => {
    showErrorToastr("Failed to update the status")

    errorHandler(error)
  }

  const onUpdateSuccess = () => {
    showSuccessToastr("The status was updated successfully")

    toggleIsUpdated()
  }

  const updateSubscriptionModificationFields = async () => {
    const payload = getSubscriptionModificationFieldsPayload(
      subscription,
      formValues
    )

    callPatchApi(
      true,
      `/subscriptions/${subscription.id}`,
      payload,
      onUpdateSuccess,
      onUpdateFail,
      "auth"
    )
  }

  const updateSubscription = async payload => {
    callPatchApi(
      true,
      `/subscriptions`,
      payload,
      updateSubscriptionModificationFields,
      onUpdateFail,
      "auth"
    )
  }

  const toggleDropdown = index => {
    setIsDropdownOpen(prevState => ({
      ...prevState,
      [index]: !prevState[index],
    }))
  }

  const handleSelectedDropdownValues = (index, value) => {
    setSelectedDropdownValues(prevState => ({ ...prevState, [index]: value }))
  }

  const handleFormInput = (index, value) => {
    if (index === "reasonSubcategory") {
      value = subcategoriesKeysMap[formValues.reasonCategory][value]
    }

    setFormValues(prevState => ({ ...prevState, [index]: value }))
  }

  const handleCategoryChange = selectedCategory => {
    const categoryKey = categoriesKeysMap[selectedCategory]
    const subcategories = subcategoriesLabelsMap[categoryKey]

    setDefaultValues(prevState => ({
      ...prevState,
      reasonSubcategory: subcategories,
    }))

    handleSelectedDropdownValues(1, null)
  }

  const handleUpdateSubscription = async () => {
    if (!isFormValid) {
      return
    }

    const payload = getSubscriptionStatusPayload(subscription, formValues)

    const confirm = await swal({
      title: "Are you sure?",
      text: "Are you sure that you want to update this subscription?",
      icon: "warning",
      buttons: [true, true],
      dangerMode: true,
    })

    if (!confirm) {
      return
    }

    await updateSubscription(payload)

    toggle()
  }

  const handleSubmit = async () => {
    await handleUpdateSubscription()
  }

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="subscription-status-modal"
      tabIndex="-1"
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>Status</ModalHeader>
      <ModalBody>
        <div className="flex-container wrap">
          <div className="item">
            <DropdownComponent
              index={0}
              title="Reason Category"
              valueKey="reasonCategory"
              dropdownOpen={isDropdownOpen}
              toggleDropdown={toggleDropdown}
              selectedDropdownValues={selectedDropdownValues}
              handleSelectedDropdownValues={handleSelectedDropdownValues}
              defaultValues={defaultValues}
              handleFormInput={handleFormInput}
              handleCategoryChange={handleCategoryChange}
              categoriesKeysMap={categoriesKeysMap}
            />
          </div>

          <div className="item">
            <DropdownComponent
              index={1}
              title="Reason Subcategory"
              valueKey="reasonSubcategory"
              dropdownOpen={isDropdownOpen}
              toggleDropdown={toggleDropdown}
              selectedDropdownValues={selectedDropdownValues}
              handleSelectedDropdownValues={handleSelectedDropdownValues}
              defaultValues={defaultValues}
              handleFormInput={handleFormInput}
              categoriesKeysMap={categoriesKeysMap}
            />
          </div>

          <div className="item">
            <DropdownComponent
              index={2}
              title="Status"
              valueKey="status"
              dropdownOpen={isDropdownOpen}
              toggleDropdown={toggleDropdown}
              selectedDropdownValues={selectedDropdownValues}
              handleSelectedDropdownValues={handleSelectedDropdownValues}
              defaultValues={defaultValues}
              handleFormInput={handleFormInput}
            />
          </div>

          <div className="item">
            <span>Comment</span>
            <textarea
              rows="3"
              title="Comment"
              className="form-control"
              name="modification_comment"
              placeholder="Write any comment"
              onChange={e => {
                setFormValues(prevState => ({
                  ...prevState,
                  extra_comment: e.target.value,
                }))
              }}
            ></textarea>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="container-footer">
          <Button
            className="btn-accept"
            onClick={handleSubmit}
            disabled={!isFormValid}
          >
            Accept
          </Button>

          <Button className="btn-cancel" onClick={toggle}>
            Cancel
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default SubscriptionStatusModal

function getOldAndNewValues(oldValue, newValue, extra_comment) {
  return {
    old: oldValue,
    new: newValue,
    comment: extra_comment,
  }
}

function getModificationsFields(subscription, form) {
  return {
    status: getOldAndNewValues(
      subscription.status,
      normalizeStatus(form.status),
      form.extra_comment
    ),
  }
}

function getSubscriptionModificationFieldsPayload(subscription, form) {
  let modificationsReason = REASON_CATEGORY_SUBCATEGORY_NOT_DEFINED
  if (form.reasonCategory !== "" && form.reasonSubcategory !== "") {
    modificationsReason = form.reasonCategory + " - " + form.reasonSubcategory
  }

  const payload = {
    status: normalizeStatus(form.status),
    modifications_fields: getModificationsFields(subscription, form),
  }

  if (isCancellingSubscription(form.status)) {
    payload["cancellation_reason"] = modificationsReason

    return payload
  }

  payload["modifications_reason"] = modificationsReason

  return payload
}

function getSubscriptionStatusPayload(subscription, form) {
  return {
    subscription_ids: [subscription.id],
    status: normalizeStatus(form.status),
    source: backoffice_frontend_source,
  }
}
