import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  Button,
  Form,
  Spinner,
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import BasicInfo from "./Sections/basic-info"
import Priority from "./Sections/priority"
import DisplayCriteria from "./Sections/display-criteria"
import OfferSets from "./Sections/offer-sets"
import Countries from "./Sections/countries"
import PaymentMethods from "./Sections/payment-methods"
import ShippingMethods from "./Sections/shipping-methods"
import SearchProducts from "../../../components/Common/SearchProducts"
import ShippingMethodsOverride from "./Sections/shipping-methods-override"
import OffersSetModal from "./Sections/offers-set-modal"
import ClubSettings from "./Sections/club-settings"
import CrossSellingProducts from "./Sections/cross-selling-products"
import StraightChangeProducts from "./Sections/straight-change-products"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useLocation, useNavigate } from "react-router-dom"
import { callGetApi, callPutApi } from "../../../utils/api"
import {
  showSuccessToastr,
  showErrorToastr,
} from "../../../components/Common/toastr"
import "./create-funnel.scss"
import ReviewConfig from "./Sections/review-configs"

const CreateFunnel = () => {
  document.title = "Create Funnel"
  const history = useNavigate()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const funnelId = queryParams.get("funnelId")
  const shop = queryParams.get("shop")
  const [isOpen, setIsOpen] = useState(false)
  const [isOfferOpen, setIsOfferOpen] = useState(false)
  const [editOfferSetData, setEditOfferSetData] = useState(null)
  const [defaultFunnel, setDefaultFunnel] = useState(null)
  const [funnelDetails, setFunnelDetails] = useState(null)
  const [shippingMethodsUs, setShippingMethodsUs] = useState([])
  const [shippingMethodsCa, setShippingMethodsCa] = useState([])
  const [shippingMethodsGb, setShippingMethodsGb] = useState([])
  const [shippingMethodsUy, setShippingMethodsUy] = useState([])
  const [shippingMethodsAu, setShippingMethodsAu] = useState([])
  const [shippingMethodsSg, setShippingMethodsSg] = useState([])
  const [shippingMethodsNz, setShippingMethodsNz] = useState([])
  const [displayCriteriaProducts, setdisplayCriteriaProducts] = useState([])
  const [isUpdateLoading, setIsUpdateLoading] = useState(false)
  const [isDetailsLoading, setIsDetailsLoading] = useState(false)
  const [crossSellingProducts, setCrossSellingProducts] = useState([])
  const [isOpenCrossSelling, setIsOpenCrossSelling] = useState(false)
  const [autoshipCriteriaDetails, setAutoshipCriteriaDetails] = useState(null)
  const [autoshipCriteriaProducts, setAutoshipCriteriaProducts] = useState([])
  const [clubSettingsConfig, setClubSettingsConfig] = useState({})

  const countries = ["US", "CA", "GB", "UY", "AU", "SG", "NZ"]
  const [allCountries, setAllCountries] = useState(countries)
  const validationType = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      isActive: true,
      priority: "1",
      showCriteriaWhen: "any",
      shop,
      displayCriteriaProduct: {
        typeName: "product",
        checked: false,
        comparator: "any",
        value: null,
        displayCriteriaType: {
          id: 1,
          shop: null,
          name: "product",
          description: null,
          order: 0,
        },
        products: [],
      },
      displayCriteriaOrder: {
        typeName: "orderValue",
        checked: false,
        comparator: "lt",
        value: "",
        displayCriteriaType: {
          id: 3,
          shop: null,
          name: "orderValue",
          description: null,
          order: 1,
        },
        products: [],
      },
      displayCriteriaProductCount: {
        typeName: "productCount",
        checked: false,
        comparator: "lt",
        value: "",
        displayCriteriaType: {
          id: 2,
          shop: null,
          name: "productCount",
          description: null,
          order: 2,
        },
        products: [],
      },
      displayCriteriaTag: {
        typeName: "tag",
        checked: false,
        comparator: "any",
        value: "",
        displayCriteriaType: {
          id: 4,
          shop: null,
          name: "tag",
          description: null,
          order: 3,
        },
        products: [],
      },
      offers: [],
      countryCodes: "",
      paymentMethods: [],
      shippingOverrides: [],
      shippingReplacements: [],
      clubShipping: {
        subscription_name: "",
      },
      clubPlacement: {
        version: "v1",
        placement: "coupon",
        subscription_name: "",
      },
      offerSets: [],
      checkoutOffers: {
        shop: null,
        funnelId: null,
        active: false,
        checkoutOfferType: "",
      },
      reviewConfig: {},
      subscriptionConfig: {},
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Please Enter Name."),
    }),
    onSubmit: values => {
      console.log('clubSettingsConfig', clubSettingsConfig)
      let reviewConfigValues = []
      for (const c of Object.keys(values.reviewConfig)) {
        try {
          JSON.parse(values.reviewConfig[c])
        } catch (error) {
          showErrorToastr(
            `Review Config JSON validation failed for country ${c}`
          )
          return
        }
        const configObj = {
          funnelId: Number(funnelId),
          country: c,
          configJson: values.reviewConfig[c],
        }
        reviewConfigValues.push(configObj)
      }
      validationType.values.reviewConfig = reviewConfigValues

      const subscriptionConfig = []
      for (const key of Object.keys(clubSettingsConfig)) {
        const obj = clubSettingsConfig[key]
        if (obj.subscription_name) {
          const config = {
            funnelId: Number(funnelId),
            country: key,
            isPlacement: obj.type === 'placement',
            name: obj.subscription_name,
            version: obj.version,
            position: obj.placement,
          }
          subscriptionConfig.push(config)
        }
      }
      console.log('subscriptionConfig', subscriptionConfig)
      validationType.values.subscriptionConfig = subscriptionConfig
      if (subscriptionConfig.length > 0) {
        validationType.values.clubPlacement = undefined
        validationType.values.clubShipping = undefined
      }

      let details = funnelDetails || defaultFunnel
      let displayCriteria = details?.criterias?.map(el => {
        if (el.typeName == "product") {
          const displayCriteriaProducts_ =
            displayCriteriaProducts?.length > 0
              ? displayCriteriaProducts?.map(product => {
                  return {
                    productId: product?.id,
                    productName: product?.title,
                    image: product?.images?.[0]?.src,
                  }
                })
              : []
          const uniqueProducts = displayCriteriaProducts_.reduce((acc, obj) => {
            const existingProducts = acc.find(item => item.id === obj.id)
            if (!existingProducts) {
              acc.push(obj)
            }
            return acc
          }, [])
          return {
            ...el,
            checked: values.displayCriteriaProduct.checked,
            comparator: values.displayCriteriaProduct.comparator,
            value: values.displayCriteriaProduct.value,
            products: uniqueProducts,
          }
        } else if (el.typeName == "orderValue") {
          return {
            ...el,
            checked: values.displayCriteriaOrder.checked,
            comparator: values.displayCriteriaOrder.comparator,
            value: values.displayCriteriaOrder.value,
          }
        } else if (el.typeName == "productCount") {
          return {
            ...el,
            checked: values.displayCriteriaProductCount.checked,
            comparator: values.displayCriteriaProductCount.comparator,
            value: values.displayCriteriaProductCount.value,
          }
        } else if (el.typeName == "tag") {
          return {
            ...el,
            checked: values.displayCriteriaTag.checked,
            comparator: values.displayCriteriaTag.comparator,
            value: values.displayCriteriaTag.value,
          }
        }
      })
      if (
        values.displayCriteriaProduct.checked &&
        displayCriteriaProducts.length === 0
      ) {
        showErrorToastr("Please add display criteria products")
      } else {
        let paymentMethods =
          values.paymentMethods?.length > 0
            ? values.paymentMethods?.map(el => {
                if (el.paymentMethods?.[0] === ",") {
                  return {
                    ...el,
                    paymentMethods: el.paymentMethods
                      ?.replace(/\s/g, "")
                      ?.replace(",", ""),
                  }
                } else {
                  return {
                    ...el,
                    paymentMethods: el.paymentMethods?.replace(/\s/g, ""),
                  }
                }
              })
            : []
        let payload = {
          ...details,
          countryCodes: values.countryCodes?.replace(/\s/g, "") || "",
          offerSets: values.offerSets,
          offers: values.offers,
          isActive: values.isActive,
          name: values.name,
          priority: values.priority,
          showCriteriaWhen: values.showCriteriaWhen,
          shippingOverrides: values.shippingOverrides,
          shippingReplacements: values.shippingReplacements,
          paymentMethods: paymentMethods,
          clubShipping: values.clubShipping,
          clubPlacement: values.clubPlacement,
          shop: values.shop,
          criterias: displayCriteria,
          checkoutOffers: [
            {
              ...values.checkoutOffers,
              shop,
              funnelId: details.id,
              productId: crossSellingProducts?.[0]?.id,
            },
          ],
          reviewConfig: values.reviewConfig,
          subscriptionConfig: values.subscriptionConfig
        }
        callPutApi(
          true,
          `/upselling/funnels/${details?.id}?shop=${shop}`,
          payload,
          onGetUpdateFunnelSuccess,
          onGetUpdateFunnelFail
        )
      }
    },
  })
  const [selectedShippingMethods, setSelectedShippingMethods] = useState("")

  const onGetUpdateFunnelSuccess = data => {
    history(`/create-funnel?funnelId=${data.id}&shop=${data.shop}`)
    showSuccessToastr("Funnel Updated Successfully!")
    setIsUpdateLoading(false)
    loadFunnelDetails(data.id)
  }

  const onGetUpdateFunnelFail = () => {
    setIsUpdateLoading(false)
  }

  const onGetDefaultFunnelSuccess = data => {
    setIsDetailsLoading(false)
    setDefaultFunnel(data)
    validationType.setValues({
      ...validationType.values,
      name: data?.name,
      countryCodes: data?.countryCodes || "",
      isActive: data?.isActive,
      priority: data?.priority,
      showCriteriaWhen: data?.showCriteriaWhen,
      clubShipping: data?.clubShipping,
      clubPlacement: data?.clubPlacement,
      offerSets: data.offerSets,
    })
  }

  const onGetDefaultFunnelFail = () => {
    setIsDetailsLoading(false)
  }

  const loadDefaultFunnel = () => {
    setIsDetailsLoading(true)
    callGetApi(
      true,
      `/upselling/funnels/default?shop=${shop}`,
      onGetDefaultFunnelSuccess,
      onGetDefaultFunnelFail
    )
  }

  const onGetProductDetailsSuccess = data => {
    setdisplayCriteriaProducts(prevProducts => [...prevProducts, data])
  }

  const onGetProductDetailsFail = () => {}

  const loadProductDetails = id => {
    callGetApi(
      true,
      `/upselling/products/${id}`,
      onGetProductDetailsSuccess,
      onGetProductDetailsFail
    )
  }
  const onGetFunnelDetailsSuccess = data => {
    let offerSets_ = data.offerSets.map(el => {
      return {
        ...el,
        shippingOverrides: [],
      }
    })
    let displayCriteriaProduct,
      displayCriteriaOrder,
      displayCriteriaProductCount,
      displayCriteriaTag
    setdisplayCriteriaProducts([])
    data?.criterias?.map(el => {
      if (el.typeName == "product") {
        displayCriteriaProduct = {
          ...validationType.values.displayCriteriaProduct,
          checked: el?.checked,
          comparator: el?.comparator,
          value: el?.value,
        }
        el?.products?.forEach(el => loadProductDetails(el.productId))
      } else if (el.typeName == "orderValue") {
        displayCriteriaOrder = {
          ...validationType.values.displayCriteriaOrder,
          checked: el?.checked,
          comparator: el?.comparator,
          value: el?.value,
        }
      } else if (el.typeName == "productCount") {
        displayCriteriaProductCount = {
          ...validationType.values.displayCriteriaProductCount,
          checked: el?.checked,
          comparator: el?.comparator,
          value: el?.value,
        }
      } else if (el.typeName == "tag") {
        displayCriteriaTag = {
          ...validationType.values.displayCriteriaTag,
          checked: el?.checked,
          comparator: el?.comparator,
          value: el?.value,
        }
      }
    })
    if (!displayCriteriaProduct) {
      displayCriteriaProduct = {
        typeName: "product",
        checked: false,
        comparator: "any",
        value: null,
        displayCriteriaType: {
          id: 1,
          shop: null,
          name: "product",
          description: null,
          order: 0,
        },
        products: [],
      }
    }
    if (!displayCriteriaOrder) {
      displayCriteriaOrder = {
        typeName: "orderValue",
        checked: false,
        comparator: "lt",
        value: "",
        displayCriteriaType: {
          id: 3,
          shop: null,
          name: "orderValue",
          description: null,
          order: 1,
        },
        products: [],
      }
    }
    if (!displayCriteriaProductCount) {
      displayCriteriaProductCount = {
        typeName: "productCount",
        checked: false,
        comparator: "lt",
        value: "",
        displayCriteriaType: {
          id: 2,
          shop: null,
          name: "productCount",
          description: null,
          order: 2,
        },
        products: [],
      }
    }
    if (!displayCriteriaTag) {
      displayCriteriaTag = {
        typeName: "tag",
        checked: false,
        comparator: "any",
        value: "",
        displayCriteriaType: {
          id: 4,
          shop: null,
          name: "tag",
          description: null,
          order: 3,
        },
        products: [],
      }
    }
    let paymentMethods =
      data.paymentMethods?.length > 0
        ? data.paymentMethods?.map(el => {
            return {
              ...el,
              paymentMethods: el.paymentMethods?.replace(/,/g, ", "),
            }
          })
        : []
    if (data?.checkoutOffers?.length > 0) {
      setCrossSellingProducts([
        {
          ...data?.checkoutOffers?.[0]?.product,
        },
      ])
    } else {
      setCrossSellingProducts([])
    }
    validationType.setValues({
      ...validationType.values,
      name: data?.name,
      isActive: data?.isActive,
      priority: data?.priority,
      countryCodes: data?.countryCodes
        ? data?.countryCodes.replace(/,/g, ", ")
        : "",
      showCriteriaWhen: data?.showCriteriaWhen,
      offers: data?.offers,
      clubShipping: data?.clubShipping,
      clubPlacement: data?.clubPlacement,
      paymentMethods: paymentMethods,
      shippingOverrides: data?.shippingOverrides,
      reviewConfig: data?.reviewConfig,
      subscriptionConfig: data?.subscriptionConfig,
      shippingReplacements: data?.shippingReplacements,
      offerSets: offerSets_?.sort((a, b) => a.order - b.order),
      displayCriteriaProduct,
      displayCriteriaOrder,
      displayCriteriaProductCount,
      displayCriteriaTag,
      checkoutOffers:
        data?.checkoutOffers?.length > 0
          ? data?.checkoutOffers[0]
          : {
              shop: null,
              funnelId: null,
              active: false,
              checkoutOfferType: "",
            },
    })

    setIsDetailsLoading(false)
    setFunnelDetails(data)

    if (data?.subscriptionConfig.length > 0) {
      const subscriptionConfig = {}
      data.subscriptionConfig.forEach((config) => {
        subscriptionConfig[config.country] = {
          type: config.isPlacement ? 'placement': 'shipping',
          subscription_name: config.name,
          version: config.version,
          placement: config.position,
        }
      })
      setClubSettingsConfig(subscriptionConfig)
    } else {
      const determineTypeAndDetails = data => {
        if (data?.clubShipping?.subscription_name) {
          return {
            type: 'shipping',
            subscription_name: data.clubShipping.subscription_name,
          }
        }
        if (data?.clubPlacement?.subscription_name) {
          return {
            type: 'placement',
            subscription_name: data.clubPlacement.subscription_name,
            version: data.clubPlacement.version,
            placement: data.clubPlacement.placement,
          }
        }
        return {}
      }

      const createPatterns = membershipPattern => ({
        US: membershipPattern('(_\\d+)?'),
        CA: membershipPattern('_ca(_\\d+)?'),
        AU: membershipPattern('_au(_\\d+)?'),
        GB: membershipPattern('_uk(_\\d+)?'),
        SG: membershipPattern('_sg(_\\d+)?'),
        NZ: membershipPattern('_nz(_\\d+)?'),
      })

      const membershipPattern = suffix => new RegExp(`^club_membership(?:${suffix})$`)

      const getCountryFromName = name => {
        const patterns = createPatterns(membershipPattern)
        return Object.entries(patterns).find(([_, pattern]) => pattern.test(name))?.[0] || null
      }

      const subscriptionDetails = determineTypeAndDetails(data)
      if (subscriptionDetails.subscription_name) {
        const countryFound = getCountryFromName(subscriptionDetails.subscription_name)
        if (countryFound) {
          setClubSettingsConfig({
            [countryFound]: subscriptionDetails,
          })
        } else {
          if (subscriptionDetails.subscription_name.includes('truekind_membership')) {
            setClubSettingsConfig({
              'US': subscriptionDetails,
            })
          }
        }
      }
    }
  }

  const onGetFunnelDetailsFail = () => {
    setIsDetailsLoading(false)
  }

  const loadFunnelDetails = id => {
    setIsDetailsLoading(true)
    callGetApi(
      true,
      `/upselling/funnels/${id}?shop=${shop}`,
      onGetFunnelDetailsSuccess,
      onGetFunnelDetailsFail
    )
  }

  const returnSaveProducts = selectedProducts => {
    const duplicateProducts = [...selectedProducts, ...displayCriteriaProducts]
    const uniqueReplacementProducts = duplicateProducts.reduce((acc, obj) => {
      const existingProducts = acc.find(item => item.id === obj.id)
      if (!existingProducts) {
        acc.push(obj)
      }
      return acc
    }, [])
    setdisplayCriteriaProducts(uniqueReplacementProducts)
  }

  const onGetShippingMethodsSuccess = (data, country) => {
    if (country == "CA") {
      setShippingMethodsCa(data.results || [])
    } else if (country == "UY") {
      setShippingMethodsUy(data.results || [])
    } else if (country == "AU") {
      setShippingMethodsAu(data.results || [])
    } else if (country == "GB") {
      setShippingMethodsGb(data.results || [])
    } else if (country == "SG") {
      setShippingMethodsSg(data.results || [])
    } else if (country == "NZ") {
      setShippingMethodsNz(data.results || [])
    } else {
      setShippingMethodsUs(data.results || [])
    }
  }

  const onGetShippingMethodsFail = () => {}

  const getShippingStore = () => {
    let env = process.env.REACT_APP_ENV
    let shopName = ""
    if (env == "dev") {
      if (shop.includes("shapees")) {
        shopName = "shapees"
      } else if (shop.includes("truekind")) {
        shopName = "truekind"
      } else if (shop.includes("revel")) {
        shopName = "revel"
      } else if (shop.includes("thespadr")) {
        shopName = "thespadr"
      }
    } else if (env == "staging") {
      if (shop.includes("shapees")) {
        shopName = "shapees"
      } else if (shop.includes("truekind")) {
        shopName = "truekind"
      } else if (shop.includes("revel")) {
        shopName = "revel"
      } else if (shop.includes("thespadr")) {
        shopName = "thespadr"
      }
    } else if (env == "prod") {
      if (shop.includes("shapermint")) {
        shopName = "shapermint"
      } else if (shop.includes("truekind")) {
        shopName = "truekind"
      } else if (shop.includes("revel")) {
        shopName = "revel"
      } else if (shop.includes("the-spa-dr") || shop.includes("thespadr")) {
        shopName = "thespadr"
      }
    }
    return shopName
  }

  const storeName = getShippingStore()

  const loadShipppingMethods = country => {
    callGetApi(
      true,
      `/upselling/shipping/shipping-methods?store=${storeName}&country=${country}`,
      data => onGetShippingMethodsSuccess(data, country),
      onGetShippingMethodsFail
    )
  }

  const returnSaveCrossSellingProducts = selectedProducts => {
    setCrossSellingProducts(selectedProducts)
  }

  const onGetAutoshipProductDetailsSuccess = data => {
    setAutoshipCriteriaProducts(prevProducts => [...prevProducts, data])
  }

  const onGetAutoshipProductDetailsFail = () => {}

  const loadAutoshipProductDetails = id => {
    callGetApi(
      true,
      `/upselling/products/${id}`,
      onGetAutoshipProductDetailsSuccess,
      onGetAutoshipProductDetailsFail
    )
  }

  const onGetAutoshipCriteriaSuccess = data => {
    if (data?.products?.length > 0) {
      data?.products?.forEach(el => {
        if (el.giftProduct) {
          loadAutoshipProductDetails(el.giftProduct)
        }
        loadAutoshipProductDetails(el.productId)
      })
    }
    setAutoshipCriteriaDetails(data)
  }

  const onGetAutoshipCriteriaFail = () => {}

  useEffect(() => {
    countries.forEach(country => {
      loadShipppingMethods(country)
    })
    setAllCountries(countries)
  }, [])

  useEffect(() => {
    if (shop) {
      if (funnelId) {
        loadFunnelDetails(funnelId)
      } else {
        loadDefaultFunnel()
      }
    } else {
      history("/all-funnels")
    }
  }, [])

  const getAutoshipCriteriaDetails = () => {
    callGetApi(
      true,
      `/upselling/criterias/autoship/${defaultFunnel?.id || funnelDetails?.id}`,
      onGetAutoshipCriteriaSuccess,
      onGetAutoshipCriteriaFail
    )
  }

  useEffect(() => {
    if (defaultFunnel?.id || funnelDetails?.id) {
      getAutoshipCriteriaDetails()
    }
  }, [defaultFunnel?.id, funnelDetails?.id])

  useEffect(() => {
    const selectedCountries = validationType.values.countryCodes
      .split(", ")
      .filter(Boolean)
    setClubSettingsConfig(prevConfig => {
      const newConfig = {}
      selectedCountries.forEach(country => {
        if (prevConfig[country]) {
          newConfig[country] = prevConfig[country]
        }
      })
      return newConfig
    })
  }, [validationType.values.countryCodes])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Funnels"} breadcrumbItem={"Create Funnel"} />
          {isDetailsLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Spinner size={"lg"} className="mx-3 mb-0 mt-1" />
            </div>
          ) : (
            <div>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validationType.handleSubmit()
                  return false
                }}
              >
                <Row>
                  <Col lg={12}>
                    <BasicInfo validationType={validationType} />
                  </Col>
                  <Col lg={12}>
                    <DisplayCriteria
                      validationType={validationType}
                      setIsOpen={setIsOpen}
                      displayCriteriaProducts={displayCriteriaProducts}
                      setdisplayCriteriaProducts={setdisplayCriteriaProducts}
                    />
                  </Col>
                  <Col lg={12}>
                    <Countries
                      validationType={validationType}
                      allCountries={allCountries}
                    />
                  </Col>
                  <Col lg={12}>
                    <PaymentMethods
                      validationType={validationType}
                      allCountries={allCountries}
                    />
                  </Col>
                  <Col lg={12}>
                    <ShippingMethods
                      validationType={validationType}
                      allCountries={allCountries}
                      shippingMethodsUs={shippingMethodsUs}
                      shippingMethodsCa={shippingMethodsCa}
                      shippingMethodsGb={shippingMethodsGb}
                      shippingMethodsUy={shippingMethodsUy}
                      shippingMethodsAu={shippingMethodsAu}
                      shippingMethodsSg={shippingMethodsSg}
                      shippingMethodsNz={shippingMethodsNz}
                      funnelId={defaultFunnel?.id || funnelDetails?.id}
                    />
                  </Col>
                  <Col lg={12}>
                    <ShippingMethodsOverride
                      validationType={validationType}
                      shippingMethodsUs={shippingMethodsUs}
                      shippingMethodsCa={shippingMethodsCa}
                      shippingMethodsGb={shippingMethodsGb}
                      shippingMethodsUy={shippingMethodsUy}
                      shippingMethodsAu={shippingMethodsAu}
                      shippingMethodsSg={shippingMethodsSg}
                      shippingMethodsNz={shippingMethodsNz}
                      selectedShippingMethods={selectedShippingMethods}
                      setSelectedShippingMethods={setSelectedShippingMethods}
                    />
                  </Col>
                  <Col lg={12}>
                    <ReviewConfig
                      validationType={validationType}
                      allCountries={allCountries}
                    />
                  </Col>
                  <Col lg={12}>
                    <ClubSettings
                      clubSettingsConfig={clubSettingsConfig}
                      setClubSettingsConfig={setClubSettingsConfig}
                      validationType={validationType}
                      allCountries={allCountries}
                    />
                  </Col>
                  <Col lg={12}>
                    <Priority
                      validationType={validationType}
                      funnelId={funnelDetails?.id || defaultFunnel?.id}
                      shop={shop}
                    />
                  </Col>
                  <Col lg={12}>
                    <CrossSellingProducts
                      validationType={validationType}
                      setIsOpenCrossSelling={setIsOpenCrossSelling}
                      crossSellingProducts={crossSellingProducts}
                    />
                  </Col>
                  {shop?.includes("shapees") ||
                  shop?.includes("shapermint") ||
                  shop?.includes("truekind") ? (
                    <Col lg={12}>
                      <StraightChangeProducts
                        validationType={validationType}
                        funnelId={funnelDetails?.id || defaultFunnel?.id}
                        shop={shop}
                        autoshipCriteriaDetails={autoshipCriteriaDetails}
                        autoshipCriteriaProducts={autoshipCriteriaProducts}
                        setAutoshipCriteriaDetails={setAutoshipCriteriaDetails}
                        setAutoshipCriteriaProducts={setAutoshipCriteriaProducts}
                        getAutoshipCriteriaDetails={getAutoshipCriteriaDetails}
                      />
                    </Col>
                  ) : (
                    ""
                  )}
                  <Col lg={12}>
                    <OfferSets
                      validationType={validationType}
                      setIsOfferOpen={setIsOfferOpen}
                      setEditOfferSetData={setEditOfferSetData}
                      shop={shop}
                    />
                  </Col>
                  <Col lg={12}>
                    <Card>
                      <CardBody>
                        <div>
                          <div className="d-flex flex-wrap gap-2 form-btn">
                            <Button type="submit" color="primary">
                              {isUpdateLoading ? (
                                <Spinner
                                  size={"sm"}
                                  className="mx-3 mb-0 mt-1"
                                />
                              ) : (
                                "Submit"
                              )}
                            </Button>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Form>
            </div>
          )}
        </Container>
        <Modal
          isOpen={isOpen}
          toggle={() => {
            setIsOpen(!isOpen)
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              Add product
            </h5>
            <button
              type="button"
              onClick={() => {
                setIsOpen(false)
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <SearchProducts
              setIsOpen={setIsOpen}
              returnSaveProducts={returnSaveProducts}
              multiProducts
            />
          </div>
        </Modal>
        <Modal
          isOpen={isOfferOpen}
          toggle={() => {
            setIsOfferOpen(!isOfferOpen)
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              Edit Offer Set
            </h5>
            <button
              type="button"
              onClick={() => {
                setIsOfferOpen(false)
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <OffersSetModal
              setIsOpen={setIsOfferOpen}
              editOfferSetData={editOfferSetData}
              validationType={validationType}
              shop={shop}
            />
          </div>
        </Modal>
        <Modal
          isOpen={isOpenCrossSelling}
          toggle={() => {
            setIsOpenCrossSelling(!isOpenCrossSelling)
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              Add product
            </h5>
            <button
              type="button"
              onClick={() => {
                setIsOpenCrossSelling(false)
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <SearchProducts
              setIsOpen={setIsOpenCrossSelling}
              returnSaveProducts={returnSaveCrossSellingProducts}
            />
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}

export default CreateFunnel
