import { useCallback, useEffect, useState } from "react"
import { Table } from "reactstrap"
import swal from "sweetalert"
import { URL_FOR_AUTH, callGetApi, callPostApi } from "utils/api"
import {
  showErrorToastr,
  showInfoToastr,
} from "../../../../../components/Common/toastr"

const applyRefundSchedule = (
  schedId,
  paymentId,
  reason,
  doneCallback,
  failCallback
) => {
  callPostApi(
    true,
    `/schedules/${schedId}/refund`,
    {
      payment_id: paymentId,
      reason,
    },
    doneCallback,
    failCallback,
    URL_FOR_AUTH
  )
}

const getScheduleWithPaymentInfo = (subId, doneCallback, failCallback) => {
  callGetApi(
    true,
    `/subscriptions/${subId}/payments`,
    doneCallback,
    failCallback,
    URL_FOR_AUTH
  )
}

const openRefundReasonModal = scheduleId =>
  new Promise((res, rej) => {
    const divElement = document.createElement("div")
    const inputElement = document.createElement("textarea")
    inputElement.placeholder = "Explain the reason why you want to refund"
    inputElement.style.width = "100%"
    divElement.appendChild(inputElement)

    const confirmButtonClass = "btn-confirm-schedule-refund"

    swal({
      title: "Are you sure?",
      text: `Are you sure that you want to refund this schedule '${scheduleId}'`,
      icon: "warning",
      content: divElement,
      buttons: {
        cancel: "Cancel",
        confirm: {
          text: "Ok",
          closeModal: true,
          className: confirmButtonClass,
          disabled: true,
        },
      },
      dangerMode: true,
    })
      .then(value => {
        res({
          confirm: value,
          text: inputElement.value.trim(),
        })
      })
      .catch(err => {
        rej(err)
      })

    const confirmButton = document.querySelector(`.${confirmButtonClass}`)
    confirmButton.disabled = true

    inputElement.addEventListener("input", () => {
      const inputText = inputElement.value.trim()
      const disableButton = inputText.length === 0

      if (confirmButton.disabled !== disableButton) {
        confirmButton.disabled = disableButton
      }
    })
  })

const RefundItemCell = ({ value }) => {
  return (
    <td>
      <div>
        <span className="text-center">{value}</span>
      </div>
    </td>
  )
}

const RefundButtonCell = ({ schedId, paymentId, executeRefund }) => {
  const onClickHandler = useCallback(async () => {
    const { confirm, text: refundReason } = await openRefundReasonModal(schedId)

    if (!confirm) {
      return
    }

    executeRefund(schedId, paymentId, refundReason)
  }, [schedId, paymentId, executeRefund])

  return (
    <td>
      <div className="d-flex gap-2 subscription-id-container">
        <span className="subscription-actions">
          {paymentId && (
            <box-icon
              name="refresh"
              className="icon-button"
              onClick={onClickHandler}
            ></box-icon>
          )}
        </span>
      </div>
    </td>
  )
}

const RefundItemRow = ({
  schedule_id,
  schedule_status,
  schedule_due_date,
  payment_id,
  executeRefund,
}) => {
  return (
    <tr>
      <RefundItemCell value={schedule_id} />
      <RefundItemCell value={schedule_status} />
      <RefundItemCell value={schedule_due_date} />
      <RefundItemCell value={payment_id} />
      <RefundButtonCell
        schedId={schedule_id}
        paymentId={payment_id}
        executeRefund={executeRefund}
      />
    </tr>
  )
}

const RefundTabPage = ({ subId }) => {
  const [refundData, setRefundData] = useState([])

  const fetchRefundData = useCallback(() => {
    getScheduleWithPaymentInfo(
      subId,
      data => {
        setRefundData(data)
      },
      () => {
        showErrorToastr(`Could not fetch refund data for this subscription`)
      }
    )
  }, [subId, setRefundData])

  const executeRefund = useCallback(
    (schedId, paymentId, reason) => {
      applyRefundSchedule(
        schedId,
        paymentId,
        reason,
        () => {
          fetchRefundData()
          showInfoToastr(
            `Refund request for schedule "${schedId}" was  successfully sent`
          )
        },
        () => {
          showErrorToastr(
            `Refund request for schedule "${schedId}" was not successful`
          )
        }
      )
    },
    [fetchRefundData]
  )

  useEffect(() => {
    fetchRefundData()
  }, [])

  return (
    <div className="table-responsive">
      <Table className="table align-middle table-nowrap">
        <thead>
          <tr>
            <th scope="col">Id</th>
            <th scope="col">Status</th>
            <th scope="col">Due Date</th>
            <th scope="col">Payment</th>
            <th scope="col">Refund</th>
          </tr>
        </thead>
        <tbody>
          {refundData.map(refundDataItem => (
            <RefundItemRow
              key={refundDataItem.sched_id}
              executeRefund={executeRefund}
              {...refundDataItem}
            />
          ))}
        </tbody>
      </Table>
    </div>
  )
}

export default RefundTabPage
