import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Table,
  Label,
  Input,
  Spinner,
} from "reactstrap"
import ConfirmModal from "../../../../components/Common/ConfirmModal"
import { callGetApi, callDeleteApi } from "../../../../utils/api"
import { showSuccessToastr } from "../../../../components/Common/toastr"

const StraighChangeProducts = ({
  shop,
  funnelId,
  autoshipCriteriaDetails,
  autoshipCriteriaProducts,
  getAutoshipCriteriaDetails,
  setAutoshipCriteriaProducts,
  setAutoshipCriteriaDetails,
}) => {
  const isTruekindShop = shop.includes("truekind")
  const history = useNavigate()
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showAutoshipDeleteModal, setShowAutoshipDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState(null)
  const [isDeleteLoading, setIsDeleteLoading] = useState(false)
  const [allShippingTypes, SetAllShippingTypes] = useState([])
  const [path, setPath] = useState("")

  const onConfirmClick = () => {
    setShowConfirmModal(false)
    history(path)
  }

  const onConfirmDeleteAutoship = () => {
    setShowAutoshipDeleteModal(false)
    callDeleteApi(
      true,
      `/upselling/criterias/autoship/${autoshipCriteriaDetails?.id}?funnelId=${funnelId}`,
      onDeleteAutoshipCriteriaSuccess,
      onDeleteAutoshipCriteriaFail
    )
  }

  const onDeleteAutoshipCriteriaSuccess = () => {
    showSuccessToastr("Deleted Successfully!")
    setAutoshipCriteriaProducts([])
    setAutoshipCriteriaDetails(null)
  }

  const onDeleteAutoshipCriteriaFail = () => {}

  const onConfirmDelete = () => {
    setShowDeleteModal(false)
    setIsDeleteLoading(true)
    callDeleteApi(
      true,
      `/upselling/criterias/autoship/product/remove/${deleteId}?funnelId=${funnelId}`,
      onDeleteProductSuccess,
      onDeleteProductFail
    )
  }

  const onDeleteProductSuccess = () => {
    setIsDeleteLoading(false)
    showSuccessToastr("Deleted Successfully!")
    getAutoshipCriteriaDetails()
  }

  const onDeleteProductFail = () => {
    setIsDeleteLoading(false)
  }

  const onGetAllShippingTypesSuccess = data => {
    let modifiedShipping = []
    data?.map(el => {
      if (el.name?.includes("autoship")) {
        modifiedShipping.push(el)
      }
    })
    SetAllShippingTypes(modifiedShipping)
  }

  const onGetAllShippingTypesFail = () => {}

  const loadAllShippingTypes = () => {
    callGetApi(
      true,
      `/upselling/subscription_types`,
      onGetAllShippingTypesSuccess,
      onGetAllShippingTypesFail
    )
  }

  useEffect(() => {
    loadAllShippingTypes()
  }, [])
  return (
    <div>
      <div>
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <CardTitle>
                  <div className="d-flex items-center">
                    <div
                      className="priority-btn-section"
                      style={{ width: "100%" }}
                    >
                      <span>Autoship at Checkout{"'"}s Order Summary</span>
                      <div>
                        {autoshipCriteriaDetails && (
                          <Button
                            type="button"
                            color="link"
                            className="btn btn-link waves-effect"
                            style={{ padding: 1, marginRight: 15 }}
                            onClick={() => {
                              setShowAutoshipDeleteModal(true)
                            }}
                          >
                            Delete Autoship Criteria
                          </Button>
                        )}
                        <Button
                          type="button"
                          color="link"
                          className="btn btn-link waves-effect"
                          style={{ padding: 1 }}
                          onClick={() => {
                            setPath(
                              `/add-autoship-criteria/${funnelId}?shop=${shop}`
                            )
                            setShowConfirmModal(true)
                          }}
                        >
                          {autoshipCriteriaDetails
                            ? "Edit Autoship Criteria"
                            : "Add Autoship Criteria"}
                        </Button>
                      </div>
                    </div>
                  </div>
                </CardTitle>
                <div>
                  {autoshipCriteriaDetails && (
                    <div>
                      <div className="mb-3">
                        <Label className="form-label">Subscription Name</Label>
                        <select
                          value={autoshipCriteriaDetails?.subscriptionName}
                          className={`form-select`}
                          disabled
                        >
                          <option value="">Select Subscription Name</option>
                          {allShippingTypes?.map((el, ind) => {
                            return (
                              <option value={el.name} key={ind}>
                                {el.name} - {el.title}
                              </option>
                            )
                          })}
                        </select>
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">
                          Subscription Threshold
                        </Label>
                        <Input
                          value={autoshipCriteriaDetails?.threshold}
                          disabled
                        />
                      </div>
                      {/* <div className="mb-3">
                        <Label className="form-label">Status</Label>
                        <select
                          value={autoshipCriteriaDetails?.active.toString()}
                          className={`form-select`}
                          disabled
                        >
                          <option value={true.toString()}>Active</option>
                          <option value={false.toString()}>Inactive</option>
                        </select>
                      </div> */}
                    </div>
                  )}
                  {autoshipCriteriaDetails?.products?.length > 0 ? (
                    <div style={{ marginTop: 10 }}>
                      <div className="table-responsive">
                        <Table className="table table-bordered mb-0 funnel-table">
                          <thead>
                            <tr>
                              <th>S.No</th>
                              <th>Product</th>
                              <th>Priority</th>
                              {!isTruekindShop && <th>Gift Product</th>}
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {autoshipCriteriaDetails?.products
                              ?.sort((a, b) => a.priority - b.priority)
                              ?.map((el, index) => {
                                let product = null
                                let giftProduct = null
                                autoshipCriteriaProducts?.map(ol => {
                                  if (ol?.id == el.productId) {
                                    product = ol
                                  }
                                  if (ol?.id == el.giftProduct) {
                                    giftProduct = ol
                                  }
                                })
                                return (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>
                                      <div
                                        className="selected-product-item"
                                        style={{
                                          padding: 0,
                                          border: "none",
                                        }}
                                      >
                                        <img src={product?.images?.[0]?.src} />
                                        <Label className="form-label">
                                          {product?.title}
                                        </Label>
                                      </div>
                                    </td>
                                    <td>{el.priority}</td>
                                    {!isTruekindShop && (
                                      <td>
                                        <div
                                          className="selected-product-item"
                                          style={{
                                            padding: 0,
                                            border: "none",
                                          }}
                                        >
                                          <img
                                            src={giftProduct?.images?.[0]?.src}
                                          />
                                          <Label className="form-label">
                                            {giftProduct?.title}
                                          </Label>
                                        </div>
                                      </td>
                                    )}
                                    <td>
                                      {isDeleteLoading && el.id == deleteId ? (
                                        <Spinner
                                          size={"sm"}
                                          className="mx-3 mb-0 mt-1"
                                        />
                                      ) : (
                                        <i
                                          className="mdi mdi-delete"
                                          style={{
                                            fontSize: 23,
                                            cursor: "pointer",
                                            position: "relative",
                                            top: "10px",
                                          }}
                                          onClick={() => {
                                            setShowDeleteModal(true)
                                            setDeleteId(el.id)
                                          }}
                                        ></i>
                                      )}
                                    </td>
                                  </tr>
                                )
                              })}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <ConfirmModal
        show={showAutoshipDeleteModal}
        onConfirmClick={onConfirmDeleteAutoship}
        onCloseClick={() => setShowAutoshipDeleteModal(false)}
        message="Are you sure you want to delete this permanently?"
      />
      <ConfirmModal
        show={showConfirmModal}
        onConfirmClick={onConfirmClick}
        onCloseClick={() => setShowConfirmModal(false)}
        message="If you have unsaved changes it will be removed. Are you sure you want to leave?"
      />
      <ConfirmModal
        show={showDeleteModal}
        onConfirmClick={onConfirmDelete}
        isDelete
        onCloseClick={() => setShowDeleteModal(false)}
        message="Are you sure you want to delete this permanently?"
      />
    </div>
  )
}

export default StraighChangeProducts
