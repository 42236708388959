import { useEffect, useState } from "react"
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap"
import swal from "sweetalert"
import {
  showErrorToastr,
  showSuccessToastr,
} from "../../../../../components/Common/toastr"
import { callPatchApi } from "../../../../../utils/api"
import { errorHandler } from "../../../../../utils/error"
import {
  getCategoryAndSubcategoryReasons,
  REASON_CATEGORY_SUBCATEGORY_NOT_DEFINED,
} from "../modify"
import "./price.scss"

const DropdownComponent = ({
  index,
  title,
  valueKey,
  dropdownOpen,
  toggleDropdown,
  selectedDropdownValues,
  handleSelectedDropdownValues,
  handleFormInput,
  defaultValues,
  handleCategoryChange,
  categoriesKeysMap,
}) => (
  <>
    <span>{title}</span>
    <Dropdown isOpen={dropdownOpen[index]} toggle={() => toggleDropdown(index)}>
      <DropdownToggle caret>
        {selectedDropdownValues[index] || "Select"} {}
      </DropdownToggle>
      <DropdownMenu>
        {defaultValues[valueKey] &&
          defaultValues[valueKey].map(value => (
            <DropdownItem
              key={value}
              onClick={() => {
                handleSelectedDropdownValues(index, value)
                handleFormInput(
                  valueKey,
                  valueKey === "reasonCategory"
                    ? categoriesKeysMap[value]
                    : value
                )
                if (handleCategoryChange) {
                  handleCategoryChange(value)
                }
              }}
            >
              {value}
            </DropdownItem>
          ))}
      </DropdownMenu>
    </Dropdown>
  </>
)

const InputComponent = ({
  title,
  valueKey,
  handleFormInput,
  subscription,
  readOnly = false,
  subscriptionDetails,
}) => {
  const defaultValue = getDefaultInputValueByKey(
    subscription,
    subscriptionDetails,
    valueKey
  )
  const [inputValue, setInputValue] = useState(defaultValue)

  useEffect(() => {
    handleFormInput(valueKey, defaultValue)
  }, [])

  const handleChange = event => {
    setInputValue(event.target.value)
    handleFormInput(valueKey, event.target.value)
  }

  return (
    <>
      <span>{title}</span>
      <div className="input">
        <Input
          type="text"
          value={inputValue}
          onChange={handleChange}
          readOnly={readOnly}
        />
      </div>
    </>
  )
}

function SubscriptionPriceModal({
  isOpen,
  toggle,
  subscription,
  toggleIsUpdated,
  subscriptionDetails,
}) {
  const [defaultValues, setDefaultValues] = useState({
    reasonCategory: [],
    reasonSubcategory: [],
    price: null,
    priceModification: null,
    priceModificationPeriod: null,
  })
  let [formValues, setFormValues] = useState({
    reasonCategory: "",
    reasonSubcategory: "",
    price: null,
    priceModification: null,
    priceModificationPeriod: null,
  })

  const [isDropdownOpen, setIsDropdownOpen] = useState({})
  const [selectedDropdownValues, setSelectedDropdownValues] = useState({})
  const [subcategoriesLabelsMap, setSubcategoriesLabelsMap] = useState({})

  const [categoriesKeysMap, setCategoriesKeysMap] = useState({})
  const [subcategoriesKeysMap, setSubcategoriesKeysMap] = useState({})

  const [store, setStore] = useState({})
  const [isFormValid, setIsFormValid] = useState(false)

  useEffect(() => {
    if (!isOpen) {
      return
    }

    setCategoryAndSubcategoryReasons()
  }, [isOpen])

  useEffect(() => {
    if (isOpen) {
      return
    }

    setDefaultValues({
      reasonCategory: [],
      reasonSubcategory: [],
      price: null,
      priceModification: null,
      priceModificationPeriod: null,
    })
    setFormValues({
      reasonCategory: "",
      reasonSubcategory: "",
      price: null,
      priceModification: null,
      priceModificationPeriod: null,
    })

    setIsDropdownOpen({})
    setSelectedDropdownValues({})
    setSubcategoriesLabelsMap({})
    setIsFormValid(false)
  }, [isOpen])

  useEffect(() => {
    const isPriceModificationValid =
      getNullOrFloat(formValues.priceModification) != null
    const isPriceModificationPeriodValid =
      getNullOrFloat(formValues.priceModificationPeriod) != null

    let isFormValid =
      isPriceModificationValid &&
      isPriceModificationPeriodValid &&
      formValues.reasonCategory &&
      formValues.reasonSubcategory

    if (!isFormValid) {
      return setIsFormValid(isFormValid)
    }

    const isReasonCategoryValid = !!store[formValues.reasonCategory]
    const isReasonSubcategoryValid =
      isReasonCategoryValid &&
      store[formValues.reasonCategory]["subcategories"].some(
        subcategoryMap => subcategoryMap[formValues.reasonSubcategory]
      )

    isFormValid =
      isFormValid && isReasonCategoryValid && isReasonSubcategoryValid

    setIsFormValid(isFormValid)
  }, [formValues])

  const setCategoryAndSubcategoryReasons = () => {
    const result = getCategoryAndSubcategoryReasons(subscription)

    const store = result[0]
    const categoriesKeysMap = result[1]
    const subcategoriesKeysMap = result[2]
    const categoriesLabels = result[3]
    const subcategoriesLabels = result[4]

    setStore(store)
    setCategoriesKeysMap(categoriesKeysMap)
    setSubcategoriesKeysMap(subcategoriesKeysMap)
    setSubcategoriesLabelsMap(subcategoriesLabels)

    setDefaultValues(prevState => ({
      ...prevState,
      reasonCategory: categoriesLabels,
    }))
  }

  const onUpdateFail = error => {
    showErrorToastr("Failed to update the price")

    errorHandler(error)
  }

  const onUpdateSuccess = () => {
    showSuccessToastr("The price was updated successfully")

    toggleIsUpdated()
  }

  const updateSubscription = async (subscriptionId, payload) => {
    callPatchApi(
      true,
      `/subscriptions/${subscriptionId}`,
      payload,
      onUpdateSuccess,
      onUpdateFail,
      "auth"
    )
  }

  const toggleDropdown = index => {
    setIsDropdownOpen(prevState => ({
      ...prevState,
      [index]: !prevState[index],
    }))
  }

  const handleSelectedDropdownValues = (index, value) => {
    setSelectedDropdownValues(prevState => ({ ...prevState, [index]: value }))
  }

  const handleFormInput = (index, value) => {
    if (index === "reasonSubcategory") {
      value = subcategoriesKeysMap[formValues.reasonCategory][value]
    }

    setFormValues(prevState => ({ ...prevState, [index]: value }))
  }

  const handleCategoryChange = selectedCategory => {
    const categoryKey = categoriesKeysMap[selectedCategory]
    const subcategories = subcategoriesLabelsMap[categoryKey]

    setDefaultValues(prevState => ({
      ...prevState,
      reasonSubcategory: subcategories,
    }))

    handleSelectedDropdownValues(2, null)
  }

  const handleUpdateSubscription = async () => {
    if (!isFormValid) {
      return
    }

    const payload = getPayload(subscriptionDetails, formValues)

    const confirm = await swal({
      title: "Are you sure?",
      text: "Are you sure that you want to update this subscription?",
      icon: "warning",
      buttons: [true, true],
      dangerMode: true,
    })

    if (!confirm) {
      return
    }

    await updateSubscription(subscription.id, payload)

    toggle()
  }

  const handleSubmit = async () => {
    await handleUpdateSubscription()
  }

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="subscription-price-modal"
      tabIndex="-1"
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>Price</ModalHeader>
      <ModalBody>
        <div className="flex-container wrap">
          <div className="item">
            <InputComponent
              title="Price"
              valueKey="price"
              handleFormInput={handleFormInput}
              subscription={subscription}
              readOnly={true}
            />
          </div>

          <div className="item">
            <InputComponent
              title="Price Modification"
              valueKey="priceModification"
              handleFormInput={handleFormInput}
              subscription={subscription}
              subscriptionDetails={subscriptionDetails}
            />
          </div>

          <div className="item">
            <InputComponent
              title="Price Modification Period"
              valueKey="priceModificationPeriod"
              handleFormInput={handleFormInput}
              subscription={subscription}
              subscriptionDetails={subscriptionDetails}
            />
          </div>

          <div className="item">
            <DropdownComponent
              index={1}
              title="Reason Category"
              valueKey="reasonCategory"
              dropdownOpen={isDropdownOpen}
              toggleDropdown={toggleDropdown}
              selectedDropdownValues={selectedDropdownValues}
              handleSelectedDropdownValues={handleSelectedDropdownValues}
              defaultValues={defaultValues}
              handleFormInput={handleFormInput}
              handleCategoryChange={handleCategoryChange}
              categoriesKeysMap={categoriesKeysMap}
            />
          </div>

          <div className="item">
            <DropdownComponent
              index={2}
              title="Reason Subcategory"
              valueKey="reasonSubcategory"
              dropdownOpen={isDropdownOpen}
              toggleDropdown={toggleDropdown}
              selectedDropdownValues={selectedDropdownValues}
              handleSelectedDropdownValues={handleSelectedDropdownValues}
              defaultValues={defaultValues}
              handleFormInput={handleFormInput}
              categoriesKeysMap={categoriesKeysMap}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="container-footer">
          <Button
            className="btn-accept"
            onClick={handleSubmit}
            disabled={!isFormValid}
          >
            Accept
          </Button>

          <Button className="btn-cancel" onClick={toggle}>
            Cancel
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default SubscriptionPriceModal

function getNullOrFloat(value) {
  return value != null && !isNaN(value) && value !== ""
    ? parseFloat(value)
    : null
}

function getDefaultInputValueByKey(
  subscription,
  subscriptionDetails,
  inputKey
) {
  switch (inputKey) {
    case "price":
      return subscription?.price ? subscription.price : null

    case "priceModification":
      return subscriptionDetails?.price_modification
        ? subscriptionDetails.price_modification
        : null

    case "priceModificationPeriod":
      return subscriptionDetails?.price_modification_period
        ? subscriptionDetails.price_modification_period
        : null
  }
}

function getOldAndNewValues(oldValue, newValue) {
  return {
    old: oldValue,
    new: newValue,
  }
}

function getModificationsFields(subscriptionDetails, form) {
  return {
    price_modification: getOldAndNewValues(
      getNullOrFloat(subscriptionDetails.price_modification),
      getNullOrFloat(form.priceModification)
    ),
    price_modification_period: getOldAndNewValues(
      getNullOrFloat(subscriptionDetails.price_modification_period),
      getNullOrFloat(form.priceModificationPeriod)
    ),
  }
}

function getPayload(subscriptionDetails, form) {
  let modificationsReason = REASON_CATEGORY_SUBCATEGORY_NOT_DEFINED
  if (form.reasonCategory !== "" && form.reasonSubcategory !== "") {
    modificationsReason = form.reasonCategory + " - " + form.reasonSubcategory
  }

  return {
    modifications_reason: modificationsReason,
    modifications_fields: getModificationsFields(subscriptionDetails, form),
    price_modification: getNullOrFloat(form.priceModification),
    price_modification_period: getNullOrFloat(form.priceModificationPeriod),
  }
}
