import classnames from "classnames"
import { useState } from "react"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import RefundTabPage from "./RefundTab"
import { SubscriptionDetails } from "./SubscriptionDetails"
import { getInitialDeliveryPeriod } from "./SubscriptionDetails/SubscriptionDetails"
import { SubscriptionHistory } from "./SubscriptionHistory"

function SubscriptionDetailsModal({
  isOpen,
  toggle,
  subscription,
  subscriptionHistory,
  subscriptionDetails,
  onRefetchSubscriptions,
}) {
  const [activeTab, setActiveTab] = useState("1")

  const toggleTab = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const initialDeliveryPeriod = getInitialDeliveryPeriod(
    subscriptionDetails?.business_rules
  )

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
      size="xl"
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Subscription Details</ModalHeader>
        <ModalBody>
          <p>
            Subscription ID:{" "}
            <span className="text-primary">{subscription?.id}</span>
          </p>

          <p>
            Delivery Period:{" "}
            <span className="text-primary">{initialDeliveryPeriod}</span>
          </p>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  toggleTab("1")
                }}
              >
                Details
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "2" })}
                onClick={() => {
                  toggleTab("2")
                }}
              >
                Modification History
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "3" })}
                onClick={() => {
                  toggleTab("3")
                }}
              >
                Refunds
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              {subscription?.id ? (
                <SubscriptionDetails
                  subscriptionDetails={subscriptionDetails}
                  subscription={subscription}
                  onRefetchSubscriptions={onRefetchSubscriptions}
                />
              ) : null}
            </TabPane>
            <TabPane tabId="2" id="modalDetails">
              {subscription?.id ? (
                <SubscriptionHistory
                  subscriptionHistory={subscriptionHistory}
                  onRefetchSubscriptions={onRefetchSubscriptions}
                />
              ) : null}
            </TabPane>
            <TabPane tabId="3" id="modalRefunds">
              {subscription?.id && <RefundTabPage subId={subscription.id} />}
            </TabPane>
          </TabContent>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

export default SubscriptionDetailsModal
